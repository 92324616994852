import { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { BlogPost } from "../utils/interface";
import Loader from "../components/ui/Loader";
import Head from "../components/seo/Head";
import useBlogBost from "../hooks/useBlogPost";
import { downloadImage } from "../api/firebase";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { timeStampConverter } from "../utils/timeStampConverter";
import { blogCategories } from "../data/BlogData";

interface ImageUrls {
  [key: string]: string;
}

const BlogPostDetails = () => {
  const { slug } = useParams<{ slug: string }>();

  const {
    fetchBlogPost: { isLoading, error, data },
  } = useBlogBost({ slug });

  const { allBlogPost } = useBlogBost();
  const location = useLocation();

  const [blogData, setBlogData] = useState<BlogPost | undefined>(undefined);
  const [similarBlog, setSimilarBlog] = useState<BlogPost[]>([]);
  const [imageContentUrl, setImageContentUrl] = useState<string[]>([]);
  const [imageUrls, setImageUrls] = useState<{ [key: string]: string }>({});

  console.log({ "to do blogs": similarBlog, "to do images": imageContentUrl });

  useEffect(() => {
    if (data && allBlogPost.data) {
      setBlogData(data);
      const category = data.category;
      const similar = allBlogPost.data.filter(
        (blog) => blog.id !== data.id && blog.category.includes(category)
      );
      // Shuffle the similar safaris array
      const shuffledSimilarSafaris = similar.sort(() => 0.5 - Math.random());
      // Get three random items from the shuffled array
      const randomThreeSimilarSafaris = shuffledSimilarSafaris.slice(0, 3);
      setSimilarBlog(randomThreeSimilarSafaris);
    }
  }, [data, allBlogPost.data]);

  useEffect(() => {
    if (allBlogPost.data) {
      const fetchImageUrls = async () => {
        const urls = await Promise.all(
          allBlogPost.data.map(async (blog) => {
            const url = await downloadImage(blog.header_image);
            return { slug: blog.slug, url };
          })
        );

        const filteredUrls = urls.filter((item) => item.url !== undefined);

        const urlsObject = filteredUrls.reduce((acc, { slug, url }) => {
          acc[slug] = url as string;
          return acc;
        }, {} as ImageUrls);

        setImageUrls(urlsObject);
      };

      if (allBlogPost.data.length > 0) {
        fetchImageUrls();
      }
    }
  }, [allBlogPost.data]);

  useEffect(() => {
    if (blogData) {
      const fetchImagesUrls = async () => {
        await Promise.all(
          blogData.content.map(async (item) => {
            if (item.type === "images" && Array.isArray(item.value)) {
              await Promise.all(
                item.value.map(async (val) => {
                  const url = await downloadImage(val);
                  if (url) {
                    setImageContentUrl((prev) => [...prev, url]);
                  }
                })
              );
            }
          })
        );
      };

      fetchImagesUrls();
    }
  }, [blogData]);

  const getRecentPosts = () => {
    if (allBlogPost.data && blogData) {
      // Sort posts by published date (most recent first)

      const posts = [...allBlogPost.data].filter(
        (post) => post.id !== blogData.id
      );

      const sortedPosts = posts.sort((a, b) => {
        const dateA = new Date(
          timeStampConverter(a.publish_date, "readableDate")
        ).getTime();
        const dateB = new Date(
          timeStampConverter(b.publish_date, "readableDate")
        ).getTime();
        return dateB - dateA; // Sort descending
      });

      // Return up to 3 most recent posts
      return sortedPosts.slice(0, 3);
    }
    return [];
  };

  return (
    <>
      {isLoading && (
        <>
          <div className="h-screen w-screen flex justify-center items-center">
            <Loader />
          </div>
        </>
      )}
      {error && <h1 className="text-9xl">error...</h1>}

      <div>
        {blogData && (
          <>
            <Head
              title={blogData.name}
              link={location.pathname}
              description={blogData.blog_description}
              imageCard={imageUrls[blogData.slug]}
              addPostfixTitle={true}
            />

            <div className="grid grid-cols-1 md:grid-cols-3 p-5 gap-5">
              <div className="blog col-span-2 min-h-screen overflow-y-visible bg-colorCard shadow px-5">
                <div className="flex flex-col items-center">
                  <h1 className=" font-fontMainBold font-semibold text-2xl md:text-3xl capitalize ">
                    {blogData.name}
                  </h1>
                  <div className="w-full h-[1px] bg-colorTextBase"></div>
                </div>
                <div className="flex justify-center relative  overflow-hidden h-96 pt-5">
                  <img
                    src={imageUrls[blogData.slug]}
                    className=" h-full w-full object-cover"
                    alt={`${blogData.name}-pic`}
                  />
                </div>
                {/* {blog.content
                .filter((item) => item.type === "text")
                .map((filteredItem, index) => (
                  <div key={index} className="p-2">
                    <Markdown remarkPlugins={[remarkGfm]}>
                      {Array.isArray(filteredItem.value)
                        ? filteredItem.value.join(" ") 
                        : filteredItem.value}
                    </Markdown>
                  </div>
                ))} */}
                <div className="py-10">
                  {blogData.content
                    .filter((item) => item.type === "text")
                    .map((filteredItem, index) => (
                      <div key={index} className="px-2 py-4 leading-normal">
                        <Markdown remarkPlugins={[remarkGfm]}>
                          {Array.isArray(filteredItem.value)
                            ? filteredItem.value.join(" ")
                            : filteredItem.value}
                        </Markdown>
                      </div>
                    ))}
                </div>
                {/* TO DO */}
                {/* <div className="w-full mt-5 flex flex-col items-center px-3 sm:px-0"></div>
                <div className="px-0 sm:px-4 ">
                  <h1 className="tracking-widest capitalize md:text-2xl text-base font-bold bg-secondary p-2 text-center">
                    You may also like
                  </h1>
                  <ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-6 w-full px-2 my-5 ">
                    {similarBlog.map((blog) => {
                      return (
                        <li
                          key={blog.id}
                          className="flex flex-col justify-between"
                        >
                          {blog.name}
                        </li>
                      );
                    })}
                  </ul>
                </div> */}
              </div>
              <div className="blog-sidebar col-span-1">
                <div className="sticky top-5 space-y-4">
                  <div className="flex flex-col overflow-hidden shadow bg-colorCard ">
                    <div className="pt-3 p-5">
                      <div className="border-b border-colorTextBase w-full">
                        <h1 className="font-semibold text-lg">Recent post</h1>
                      </div>
                      <div className="divide-y divide-white/5">
                        {getRecentPosts().map((post) => (
                          <Link to={`/blog/${post.slug}`} key={post.id}>
                            <div className="py-5 flex items-center gap-x-3">
                              <div className="rounded-lg overflow-hidden w-24 h-24">
                                <img
                                  src={imageUrls[post.slug]}
                                  className="object-cover h-full w-full"
                                  alt={`${post.name}-pic`}
                                />
                              </div>
                              <div className=" w-3/4 flex flex-col">
                                <h3 className="text-sm font-bold capitalize line-clamp-2">
                                  {post.name}
                                </h3>
                                <div>
                                  <h3 className="text-sm line-clamp-1">
                                    {timeStampConverter(
                                      post.publish_date,
                                      "formattedDate"
                                    )}
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </Link>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col overflow-hidden shadow bg-colorCard ">
                    <div className="pt-3 p-5">
                      <div className="border-b border-colorTextBase w-full">
                        <h1 className="font-semibold text-lg">Popular Tags</h1>
                      </div>
                      <div className="flex flex-wrap gap-3 pt-3">
                        {blogCategories.map((category, index) => (
                          <h3
                            key={index}
                            className="text-xs cursor-pointer font-medium uppercase rounded-full py-1.5 px-2.5 border border-colorTextBase hover:bg-colorTextBase hover:text-white  tracking-wide whitespace-nowrap"
                          >
                            {category}
                          </h3>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default BlogPostDetails;
